import { render, staticRenderFns } from "./NewRoomCard.vue?vue&type=template&id=f14e0362&scoped=true&"
import script from "./NewRoomCard.vue?vue&type=script&lang=js&"
export * from "./NewRoomCard.vue?vue&type=script&lang=js&"
import style0 from "./NewRoomCard.vue?vue&type=style&index=0&id=f14e0362&prod&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "f14e0362",
  null
  
)

export default component.exports